import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

import { Box, Button, Link, makeStyles, Portal, Typography } from '@material-ui/core';

import type { FC } from 'react';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: 'none',
    zIndex: 2000,
    textAlign: 'justify'
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  }
}));

const CookiesNotification: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    Cookies.set('consent', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const consent: string | undefined = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography variant="body1" color="inherit">
          Folosim cookie-uri pentru a personaliza conținutul, pentru a oferi funcționalități social media si a analiza
          traficul. Vizitați{' '}
          <Link component="a" color="inherit" underline="always" href="/about/privacy" target="_blank">
            Politica de cookies
          </Link>{' '}
          pentru mai multe informații despre cum și de ce sunt folosite pe site-ul nostru.
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={handleClose} variant="contained" className={classes.action}>
            Accepta cookies
          </Button>
        </Box>
      </div>
    </Portal>
  );
};

export default CookiesNotification;
