/* eslint-disable no-use-before-define */
import PropTypes from 'prop-types';
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
    FileText as FileTextIcon, Home as HomeIcon, Map as MapIcon, Pocket as PocketIcon,
    User as UserIcon
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';

import {
    Avatar, Box, Divider, Drawer, Hidden, Link, List, ListSubheader, makeStyles, Typography
} from '@material-ui/core';

import NavItem from './NavItem';

import type { FC, ReactNode } from 'react';
interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const adminSections: Section[] = [
  {
    subheader: 'Management',
    items: [
      {
        title: 'Utilizatori',
        icon: UserIcon,
        href: '/admin/management/users',
        items: [
          {
            title: 'Lista utilizatori',
            href: '/admin/management/users'
          }
        ]
      },
      {
        title: 'Spatii',
        icon: MapIcon,
        href: '/admin/management/spaces',
        items: [
          {
            title: 'Lista spatii',
            href: '/admin/management/spaces'
          },
          {
            title: 'Harta spatii',
            href: '/admin/management/map'
          }
        ]
      },
      {
        title: 'Magazine',
        icon: HomeIcon,
        href: '/admin/management/stores',
        items: [
          {
            title: 'Lista magazine',
            href: '/admin/management/stores'
          },
          {
            title: 'Adauga magazin',
            href: '/admin/management/stores/new'
          }
        ]
      },
      {
        title: 'Facturi',
        icon: FileTextIcon,
        href: '/admin/management/invoices',
        items: [
          {
            title: 'Lista facturi',
            href: '/admin/management/invoices'
          },
          {
            title: 'Adauga factura',
            href: '/admin/management/invoices/new'
          }
        ]
      },
      {
        title: 'Cereri închiriere',
        icon: PocketIcon,
        href: '/admin/management/requests',
        items: [
          {
            title: 'Lista cereri închiriere',
            href: '/admin/management/requests'
          },
          {
            title: 'Adauga cerere închiriere',
            href: '/admin/management/requests/new'
          }
        ]
      },
      {
        title: 'Noutati',
        icon: FileTextIcon,
        href: '/admin/management/news',
        items: [
          {
            title: 'Lista noutati',
            href: '/admin/management/news'
          },
          {
            title: 'Adauga noutate',
            href: '/admin/management/news/new'
          }
        ]
      }
    ]
  }
];

const clientSections: Section[] = [
  {
    subheader: 'Chirias',
    items: [
      {
        title: 'Magazinele mele',
        icon: HomeIcon,
        href: '/client/stores'
      },
      {
        title: 'Facturi',
        icon: FileTextIcon,
        href: '/client/invoices'
      },
      {
        title: 'Cereri închiriere',
        icon: PocketIcon,
        href: '/client/requests'
      },
      {
        title: 'Noutati',
        icon: FileTextIcon,
        href: '/client/news'
      }
    ]
  }
];

function renderNavItems({ items, pathname, depth = 0 }: { items: Item[]; pathname: string; depth?: number }) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { isAdmin, user } = useAuth();
  const sections = isAdmin ? adminSections : clientSections;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to={isAdmin ? '/admin/account' : '/client/account'}>
              <Avatar
                // alt={user.firstName}
                className={classes.avatar}
                src="https://www.clipartmax.com/png/middle/248-2487966_matthew-man-avatar-icon-png.png"
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to={isAdmin ? '/admin/account' : '/client/account'}
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              <Typography variant="h5">
                {user.firstName} {user.lastName}
              </Typography>
              <Typography variant="h5">{user.email}</Typography>
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        {/* <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
