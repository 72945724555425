import React from 'react';

import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  return <img alt="Logo" src="/static/banner.svg" style={{ width: props.width || 100 }} />;
};

export default Logo;
