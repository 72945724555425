import { default as fallback1 } from './1.jpg';
import { default as default1 } from './1.webp';
import { default as fallback10 } from './10.jpg';
import { default as default10 } from './10.webp';
import { default as fallback11 } from './11.jpg';
import { default as default11 } from './11.webp';
import { default as fallback12 } from './12.jpg';
import { default as default12 } from './12.webp';
import { default as fallback2 } from './2.jpg';
import { default as default2 } from './2.webp';
import { default as fallback3 } from './3.jpg';
import { default as default3 } from './3.webp';
import { default as fallback4 } from './4.jpg';
import { default as default4 } from './4.webp';
import { default as fallback5 } from './5.jpg';
import { default as default5 } from './5.webp';
import { default as fallback6 } from './6.jpg';
import { default as default6 } from './6.webp';
import { default as fallback7 } from './7.jpg';
import { default as default7 } from './7.webp';
import { default as fallback8 } from './8.jpg';
import { default as default8 } from './8.webp';
import { default as fallback9 } from './9.jpg';
import { default as default9 } from './9.webp';

export const gallery = [
  {
    default: default1,
    fallback: fallback1
  },
  {
    default: default2,
    fallback: fallback2
  },
  {
    default: default3,
    fallback: fallback3
  },
  {
    default: default4,
    fallback: fallback4
  },
  {
    default: default5,
    fallback: fallback5
  },
  {
    default: default6,
    fallback: fallback6
  },
  {
    default: default7,
    fallback: fallback7
  },
  {
    default: default8,
    fallback: fallback8
  },
  {
    default: default9,
    fallback: fallback9
  },
  {
    default: default10,
    fallback: fallback10
  },
  {
    default: default11,
    fallback: fallback11
  },
  {
    default: default12,
    fallback: fallback12
  }
];
