import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

import {
    Avatar, Box, ButtonBase, Hidden, makeStyles, Menu, MenuItem, Typography
} from '@material-ui/core';

import type { FC } from 'react';
interface Props {
  clientView?: boolean;
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account: FC<Props> = ({ clientView }) => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef<any>(null);
  const { isAdmin, user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <Avatar
          alt={user.firstName}
          className={classes.avatar}
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGQY3yo5Tnz5RvK5Wi-OGWElC79St-oGfvNu86iwjdXQy6HjHKvFc75s8CfUlqXqDG8Xw"
        />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {user.firstName}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <Box display={{ lg: 'none', md: 'none' }}>
          {clientView ? (
            <MenuItem component={RouterLink} to={isAdmin ? '/admin' : '/client'}>
              Management
            </MenuItem>
          ) : (
            <MenuItem component={RouterLink} to="/">
              Acasa
            </MenuItem>
          )}
        </Box>
        <MenuItem component={RouterLink} to="/client/account">
          Editare cont
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
