import axios from 'axios';
import { refreshAuthToken } from 'src/contexts/JWTAuthContext';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const parsedError = {
      code: error?.response?.data?.code ?? 5000,
      name: error?.response?.data?.name ?? 'ServerError',
      message: error?.response?.data?.message ?? 'Something went wrong :(',
      field: 'submit'
    };
    const originalConfig = error.config;
    if (error?.response?.status === 403 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        const token = await refreshAuthToken();
        originalConfig.headers.Authorization = `Bearer ${token}`;
        return axiosInstance(originalConfig);
      } catch (_error) {
        if (_error.response && _error.response.data) {
          return Promise.reject(_error.response.data);
        }
        return Promise.reject(_error);
      }
    } else {
      switch (parsedError.code) {
        case 4003:
          parsedError.field = 'email';
          break;
        case 4004:
          parsedError.field = 'phone';
          break;
        case 5000:
          if (error?.response?.data) {
            parsedError.message = JSON.stringify(error.response.data);
          } else if (error?.message) {
            parsedError.message = error.message;
          }
      }
    }
    return Promise.reject(parsedError);
  }
);

export default axiosInstance;
