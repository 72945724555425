import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdminGuard from 'src/components/AdminGuard';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import LoginGuard from 'src/components/LoginGuard';
import ClientLayout from 'src/layouts/ClientLayout';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import { Routes } from 'src/types/routes';
import HomeView from 'src/views/home';

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} navigation={route.navigation} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const ArticleView = lazy(() => import('src/views/articles/ArticleView'));

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/connect',
    component: lazy(() => import('src/views/auth/ConnectView'))
  },
  {
    exact: true,
    guard: LoginGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/recover',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    path: '/reset-password/:token',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    path: '/admin',
    guard: AdminGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/admin/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/admin/management/users',
        navigation: {
          current: 'Lista utilizatori'
        },
        component: lazy(() => import('src/views/user/UserListView'))
      },
      {
        exact: true,
        path: '/admin/management/users/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/admin/management/users/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/admin/management/users/:itemId/:tab',
        navigation: {
          current: 'Editare utilizator',
          links: [{ label: 'Lista utilizatori', url: '/admin/management/users' }]
        },
        component: lazy(() => import('src/views/user/UserEditView'))
      },
      {
        exact: true,
        path: '/admin/management/spaces',
        navigation: {
          current: 'Lista spatii'
        },
        component: lazy(() => import('src/views/spaces/SpaceListView'))
      },
      {
        exact: true,
        path: '/admin/management/map',
        navigation: {
          current: 'Harta spatii'
        },
        component: lazy(() => import('src/views/spaces/SpaceMapplicView'))
      },
      {
        exact: true,
        path: '/admin/management/spaces/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/admin/management/spaces/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/admin/management/spaces/:itemId/:tab',
        navigation: {
          current: 'Editare spatiu',
          links: [{ label: 'Lista spatii', url: '/admin/management/spaces' }]
        },
        component: lazy(() => import('src/views/spaces/SpaceEditView'))
      },
      {
        exact: true,
        path: '/admin/management/spaces/:parentId/contracts/:itemId',
        navigation: {
          current: 'Editare contract',
          links: [
            { label: 'Lista spatii', url: '/admin/management/spaces' },
            { label: 'Editare spatiu', url: '/admin/management/spaces', parent: true }
          ]
        },
        component: lazy(() => import('src/views/spaces/SpaceContractEditView'))
      },
      {
        exact: true,
        path: '/admin/management/spaces/:parentId/requests/:itemId',
        component: ({
          match: {
            params: { parentId, itemId }
          }
        }) => {
          const url = `/admin/management/spaces/${parentId}/requests/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/admin/management/spaces/:parentId/requests/:itemId/:tab',
        navigation: {
          current: 'Cerere inchiriere',
          links: [
            { label: 'Lista spatii', url: '/admin/management/spaces' },
            { label: 'Editeaza spatiu', url: '/admin/management/spaces', parent: true }
          ]
        },
        component: lazy(() => import('src/views/requests/RequestEditView'))
      },
      {
        exact: true,
        path: '/admin/management/stores',
        navigation: {
          current: 'Lista magazine'
        },
        component: lazy(() => import('src/views/stores/StoreListView'))
      },
      {
        exact: true,
        path: '/admin/management/stores/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/admin/management/stores/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/admin/management/stores/:itemId/:tab',
        navigation: {
          current: 'Editare magazin',
          links: [{ label: 'Lista magazine', url: '/admin/management/stores' }]
        },
        component: lazy(() => import('src/views/stores/StoreEditView'))
      },
      {
        exact: true,
        path: '/admin/management/stores/:parentId/invoices/:itemId',
        navigation: {
          current: 'Editare factura',
          links: [
            { label: 'Lista magazine', url: '/admin/management/stores' },
            { label: 'Editare magazin', url: '/admin/management/stores', parent: true }
          ]
        },
        component: lazy(() => import('src/views/invoices/InvoiceEditView'))
      },
      {
        exact: true,
        path: '/admin/management/invoices',
        navigation: {
          current: 'Lista facturi'
        },
        component: lazy(() => import('src/views/invoices/InvoiceListView'))
      },
      {
        exact: true,
        path: '/admin/management/invoices/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/admin/management/invoices/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/admin/management/invoices/:itemId/:tab',
        navigation: {
          current: 'Editare factura',
          links: [{ label: 'Lista facturi', url: '/admin/management/invoices' }]
        },
        component: lazy(() => import('src/views/invoices/InvoiceEditView'))
      },
      {
        exact: true,
        path: '/admin/management/requests',
        navigation: {
          current: 'Lista cereri'
        },
        component: lazy(() => import('src/views/requests/RequestListView'))
      },
      {
        exact: true,
        path: '/admin/management/requests/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/admin/management/requests/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/admin/management/requests/:itemId/:tab',
        navigation: {
          current: 'Editare cerere',
          links: [{ label: 'Lista cereri', url: '/admin/management/requests' }]
        },
        component: lazy(() => import('src/views/requests/RequestEditView'))
      },
      {
        exact: true,
        path: '/admin/management/news',
        navigation: {
          current: 'Lista noutati'
        },
        component: lazy(() => import('src/views/articles/ArticleListView'))
      },
      {
        exact: true,
        path: '/admin/management/news/:itemId',
        navigation: {
          current: 'Editare noutate',
          links: [{ label: 'Lista noutati', url: '/admin/management/news' }]
        },
        component: lazy(() => import('src/views/articles/ArticleEditView'))
      },
      {
        exact: true,
        path: '/admin/management/news/:itemId/view',
        navigation: {
          current: 'Vizualizare noutate',
          links: [{ label: 'Lista noutati', url: '/admin/management/news' }]
        },
        component: lazy(() => import('src/views/articles/ArticleView'))
      },
      {
        exact: true,
        path: '/admin/management',
        component: () => <Redirect to="/admin/management/users" />
      },
      {
        exact: true,
        path: '/admin',
        component: () => <Redirect to="/admin/account" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/client',
    guard: AuthGuard,
    layout: ClientLayout,
    routes: [
      {
        exact: true,
        path: '/client/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/client/stores',
        navigation: {
          current: 'Lista magazine'
        },
        component: lazy(() => import('src/views/stores/StoreListView'))
      },
      {
        exact: true,
        path: '/client/stores/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/client/stores/${itemId}/data`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/client/stores/:itemId/:tab',
        navigation: {
          current: 'Editare magazin',
          links: [{ label: 'Lista magazine', url: '/client/stores' }]
        },
        component: lazy(() => import('src/views/stores/StoreEditView'))
      },
      {
        exact: true,
        path: '/client/stores/:parentId/invoices/:itemId/view',
        navigation: {
          current: 'Editare factura',
          links: [
            { label: 'Lista magazine', url: '/client/stores' },
            { label: 'Editare magazin', url: '/client/stores', parent: true }
          ]
        },
        component: lazy(() => import('src/views/invoices/InvoiceView'))
      },
      {
        exact: true,
        path: '/client/invoices',
        navigation: {
          current: 'Lista facturi'
        },
        component: lazy(() => import('src/views/invoices/InvoiceListView'))
      },
      {
        exact: true,
        path: '/client/invoices/:itemId/view',
        navigation: {
          current: 'Vizualizare factura',
          links: [{ label: 'Lista facturi', url: '/client/invoices' }]
        },
        component: lazy(() => import('src/views/invoices/InvoiceView'))
      },
      {
        exact: true,
        path: '/client/requests',
        navigation: {
          current: 'Lista cereri'
        },
        component: lazy(() => import('src/views/requests/RequestListView'))
      },
      {
        exact: true,
        path: '/client/requests/:itemId',
        component: ({
          match: {
            params: { itemId }
          }
        }) => {
          const url = `/client/requests/${itemId}/view`;
          return <Redirect to={url} />;
        }
      },
      {
        exact: true,
        path: '/client/requests/:itemId/view',
        navigation: {
          current: 'Vizualizare cerere',
          links: [{ label: 'Lista cereri', url: '/client/requests' }]
        },
        component: lazy(() => import('src/views/requests/RequestClientStatusView'))
      },
      {
        exact: true,
        path: '/client/news',
        navigation: {
          current: 'Lista noutati'
        },
        component: lazy(() => import('src/views/articles/ArticleListView'))
      },
      {
        exact: true,
        path: '/client/news/:itemId',
        navigation: {
          current: 'Editare noutate',
          links: [{ label: 'Lista noutati', url: '/client/news' }]
        },
        component: lazy(() => import('src/views/articles/ArticleEditView'))
      },
      {
        exact: true,
        path: '/client/news/:itemId/view',
        navigation: {
          current: 'Vizualizare noutate',
          links: [{ label: 'Lista noutati', url: '/client/news' }]
        },
        component: lazy(() => import('src/views/articles/ArticleView'))
      },
      {
        exact: true,
        path: '/client',
        component: () => <Redirect to="/client/account" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/map',
        component: lazy(() => import('src/views/spaces/SpaceClientMapplicView'))
      },
      {
        exact: true,
        path: '/news',
        component: lazy(() => import('src/views/articles/ArticleClientListView'))
      },
      {
        exact: true,
        path: '/news/:itemId/view',
        navigation: {
          current: 'Vizualizare noutate',
          links: [{ label: 'Lista noutati', url: '/news' }]
        },
        component: (props) => <ArticleView isClient={true} {...props} />
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/request/:parentId/:itemId',
        navigation: {
          current: 'Vizualizare magazin',
          links: [{ label: 'Harta', url: '/map' }]
        },
        component: lazy(() => import('src/views/requests/RequestClientView'))
      },
      {
        exact: true,
        path: '/stores',
        component: lazy(() => import('src/views/stores/StoreClientListView'))
      },
      {
        exact: true,
        path: '/stores/:itemId',
        navigation: {
          current: 'Vizualizare magazin',
          links: [{ label: 'Harta', url: '/map' }]
        },
        component: lazy(() => import('src/views/stores/StoreView'))
      },
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        exact: true,
        path: '/about/:pageId',
        component: lazy(() => import('src/components/TemplatePage'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
