import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import type { FC, ReactNode } from 'react';
interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, setRedirectUrl } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    setRedirectUrl(location.pathname);
    return <Redirect to="/login" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
