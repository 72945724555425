import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Briefcase as ManageIcon, LogIn as LoginIcon, Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import Account from 'src/layouts/DashboardLayout/TopBar/Account';
import { Item } from 'src/types/layout';

import {
    AppBar, Box, Hidden, IconButton, makeStyles, MenuItem, SvgIcon, Toolbar, Tooltip
} from '@material-ui/core';

// import Settings from '../DashboardLayout/TopBar/Settings';

import type { FC } from 'react';
import type { Theme } from 'src/theme';
interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
  sections: Item[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  },
  centeredLogo: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%,0)'
  }
}));

const TopBar: FC<TopBarProps> = ({ sections, className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const { isAdmin, isAuthenticated } = useAuth();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} aria-label="menu">
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <Box className={classes.centeredLogo}>
            <Logo />
          </Box>
        </Hidden>
        <Hidden mdDown>
          <Box mr={2}>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
          {sections.map((item) => (
            <MenuItem key={item.title} component={RouterLink} to={item.href}>
              {item.title}
            </MenuItem>
          ))}
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {isAuthenticated && (
          <Hidden smDown>
            <Tooltip title="Management">
              <IconButton component={RouterLink} to={isAdmin ? '/admin' : '/client'} color="inherit">
                <SvgIcon fontSize="small">
                  <ManageIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Hidden>
        )}
        {/* <Settings /> */}
        <Box ml={2}>
          {isAuthenticated ? (
            <Account clientView={true} />
          ) : (
            <MenuItem component={RouterLink} to="/login" aria-label="login">
              <Hidden mdUp>
                <LoginIcon />
              </Hidden>
              <Hidden smDown>Autentificare</Hidden>
            </MenuItem>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
