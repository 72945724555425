/* eslint-disable no-use-before-define */
import PropTypes from 'prop-types';
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';

import { Box, Divider, Drawer, List, makeStyles } from '@material-ui/core';

import type { FC } from 'react';
import NavItem from 'src/layouts/DashboardLayout/NavBar/NavItem';
import { Item } from 'src/types/layout';
interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  sections: Item[];
}

function renderNavItems({ items, pathname, depth = 0 }: { items: Item[]; pathname: string; depth?: number }) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });
    acc.push(
      <NavItem depth={depth} icon={item.icon} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile, sections }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2} display="flex" justifyContent="center">
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>
        <Divider />
        <Box p={2}>
          {renderNavItems({
            items: sections,
            pathname: location.pathname
          })}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.mobileDrawer }}
      onClose={onMobileClose}
      open={openMobile}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
