import React, { FC } from 'react';
import { THEMES } from 'src/constants';

import {
    Box, Container, Divider, Grid, Hidden, Link, makeStyles, Typography
} from '@material-ui/core';

import Logo from './Logo';

import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
    color: '#fff',
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : { backgroundColor: theme.palette.background.default })
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      order: 1
    }
  },
  mapContainer: {
    maxWidth: 200
  },
  mapWrapper: {
    height: 0,
    paddingTop: `${(7 / 9) * 100}%`,
    position: 'relative'
  },
  map: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
}));

const legalLinks = [
  {
    title: 'Informatii legale',
    href: '/about/legal'
  },
  {
    title: 'Termeni & Conditii',
    href: '/about/terms'
  },
  {
    title: 'Politica de confidentialitate',
    href: '/about/privacy'
  },
  {
    title: 'Contact',
    href: '#'
  }
];

const Footer: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth={false}>
        <Grid container spacing={4}>
          <Grid className={classes.logoContainer} item md={3} sm={4} xs={12}>
            <Logo width="50%" />
            <Box mt={2}>
              <Typography variant="caption" color="inherit">
                © 2021 TimeTree
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Typography color="inherit" variant="overline">
              Legal
            </Typography>
            {legalLinks.map((link) => (
              <Box pt={1} key={link.title}>
                <Link href={link.href} color="inherit" variant="subtitle2">
                  {link.title}
                </Link>
              </Box>
            ))}
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Typography color="inherit" variant="overline">
              Program de functionare de Paște
            </Typography>
            <Box pt={1}>
              <Typography color="inherit" variant="subtitle2">
                Sâmbătă
              </Typography>
            </Box>
            <Box pt={1}>
              <Typography color="inherit" variant="body2">
                07:00 - 17:00
              </Typography>
            </Box>
            <Box pt={1}>
              <Typography color="inherit" variant="subtitle2">
                Duminică
              </Typography>
            </Box>
            <Box pt={1}>
              <Typography color="inherit" variant="body2">
                Închis
              </Typography>
            </Box>
            <Box pt={1}>
              <Typography color="inherit" variant="subtitle2">
                Luni
              </Typography>
            </Box>
            <Box pt={1}>
              <Typography color="inherit" variant="body2">
                Închis
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <Typography color="inherit" variant="overline">
              Contact
            </Typography>
            <Box className={classes.mapContainer}>
              <Box mt={1} className={classes.mapWrapper}>
                <Link href="https://goo.gl/maps/DRmC5FVE7VRYpzTB7" target="_blank">
                  <img
                    className={classes.map}
                    alt="map"
                    src="https://cloud-cube-eu2.s3.eu-west-1.amazonaws.com/zj2evi66msqq/public/general/piata.JPG"
                  />
                </Link>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography color="inherit" variant="subtitle2">
                Drumul Taberei 40-44, Bucuresti
              </Typography>
            </Box>
            <Hidden smUp>
              <Box mt={2}>
                <Divider />
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
