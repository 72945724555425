import { Theme } from 'src/theme';

import { colors, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%'
  },
  carouselContainer: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 64px)'
  },
  carouselContainerMobile: {
    position: 'relative',
    maxHeight: 'calc(100vh - 100px)',
    paddingTop: '55%'
  },
  carouselImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: '50% 50%'
  },
  responsiveIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  sectionContainer: {
    minHeight: 'calc(100vh - 64px)'
  },
  sectionTitle: {
    padding: theme.spacing(8),
    fontFamily: 'Reey-Regular',
    fontSize: 54,
    [theme.breakpoints.down('sm')]: {
      fontSize: 38
    }
  },
  subsection: {
    textAlign: 'center',
    padding: theme.spacing(2)
  },
  subsectionTitle: {
    color: colors.lightGreen[700],
    paddingBottom: theme.spacing(2)
  },
  sectionText: {
    color: colors.blueGrey[700],
    paddingBottom: theme.spacing(2),
    fontWeight: 400
  },
  section2Text: {
    color: colors.common.white,
    padding: theme.spacing(3),
    fontWeight: 400,
    textAlign: 'justify'
  },
  roundImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 'auto',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: '85%'
    }
  },
  leftImageContainer: {
    [theme.breakpoints.down('sm')]: {
      height: 0,
      paddingTop: '118%',
      position: 'relative'
    }
  },
  leftImage: {
    height: '100%',
    maxWidth: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    }
  },
  sectionPadding: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3)
    }
  },
  textGreen: {
    color: colors.lightGreen[700]
  },
  textWhite: {
    color: colors.common.white,
    wordSpacing: 40
  },
  darkBackground: {
    backgroundColor: '#232428'
  },
  lightBackground: {
    backgroundColor: colors.common.white
  },
  galleryBackground: {
    backgroundColor: theme.palette.background.dark
  },
  galleryImage: {
    width: '100%',
    cursor: 'pointer'
  }
}));
