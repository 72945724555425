import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BookOpen as ArticleIcon, Home as StoreIcon, Map as MapIcon } from 'react-feather';
import Footer from 'src/components/Footer';
import { Item } from 'src/types/layout';

import { makeStyles } from '@material-ui/core';

import NavBar from './NavBar';
import TopBar from './TopBar';

import type { FC, ReactNode } from 'react';
import type { Theme } from 'src/theme';
interface MainLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const sections: Item[] = [
  {
    title: 'Acasa',
    icon: StoreIcon,
    href: '/'
  },
  {
    title: 'Harta',
    icon: MapIcon,
    href: '/map'
  },
  {
    title: 'Noutati',
    icon: ArticleIcon,
    href: '/news'
  },
  {
    title: 'Magazine',
    icon: StoreIcon,
    href: '/stores'
  }
];

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <TopBar sections={sections} onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar sections={sections} onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {children}
          <Footer />
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
