export enum USER_ROLE {
  admin = 'Admin',
  client = 'Client'
}

export interface User {
  id?: string;
  avatar?: string;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  [key: string]: any;
  role: USER_ROLE;
}

export interface Permission {
  entityName: string;
  entityId: string;
  owner: boolean;
  read: boolean;
  write: boolean;
}
