import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import type { FC, ReactNode } from 'react';
interface LoginGuardProps {
  children?: ReactNode;
}

const LoginGuard: FC<LoginGuardProps> = ({ children }) => {
  const { isAuthenticated, connectEmail } = useAuth();

  if (isAuthenticated) {
    return <Redirect to="/" />;
  } else if (!connectEmail) {
    return <Redirect to="/connect" />;
  }

  return <>{children}</>;
};

LoginGuard.propTypes = {
  children: PropTypes.node
};

export default LoginGuard;
