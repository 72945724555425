import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { USER_ROLE } from 'src/types/user';

import useAuth from '../hooks/useAuth';

import type { FC, ReactNode } from 'react';
interface AdminGuardProps {
  children?: ReactNode;
}

const AdminGuard: FC<AdminGuardProps> = ({ children }) => {
  const { isAuthenticated, role, setRedirectUrl } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    setRedirectUrl(location.pathname);
    return <Redirect to="/login" />;
  } else if (isAuthenticated && role !== USER_ROLE.admin) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
