import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import clsx from 'clsx';
import React, { FC } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { gallery, section11, section12, section13, section2, section31, section32 } from 'src/assets/home';
import Page from 'src/components/Page';
import { useWindowSize } from 'src/hooks/useWindowSize';

import { Container, Grid, Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { useStyles } from './styles';

const data = [
  {
    type: 'video',
    src: 'https://player.vimeo.com/video/689335917?h=8dd31fd552&portrait=0&byline=0&title=false&autoplay=true&loop=true'
  },
  {
    type: 'image',
    src: gallery[0]
  },
  {
    type: 'image',
    src: gallery[1]
  },
  {
    type: 'image',
    src: gallery[2]
  },
  {
    type: 'image',
    src: gallery[3]
  }
];

const HomeView: FC = () => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const isMobile = !!(windowSize?.width / windowSize?.height < 0.8);
  const actualCarouselStyle = isMobile ? classes.carouselContainerMobile : classes.carouselContainer;

  return (
    <Page className={classes.root} title="Acasa">
      <Carousel
        indicators={!isMobile}
        navButtonsAlwaysVisible={isMobile}
        autoPlay={false}
        animation="slide"
        fullHeightHover={false}
        navButtonsProps={{
          style: {
            backgroundColor: 'rgba(0,0,0, 0.4)'
          }
        }}
        indicatorContainerProps={{
          style: {
            position: 'absolute',
            bottom: 60
          }
        }}
        indicatorIconButtonProps={{
          style: {
            color: 'white'
          }
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: '#8a85ff'
          }
        }}
        IndicatorIcon={<FiberManualRecordIcon />}
      >
        {data.map((item, key) => {
          switch (item.type) {
            case 'image':
              const imageSource = item.src as { default: string; fallback: string };
              return (
                <div key={key} className={actualCarouselStyle}>
                  <picture>
                    <source srcSet={imageSource.default} type="image/webp" />
                    <img src={imageSource.fallback} alt="Piata Drumul Taberei" className={classes.carouselImg} />
                  </picture>
                </div>
              );
            case 'video':
              const videoSource = item.src as string;
              return (
                <div key={key} className={actualCarouselStyle}>
                  <iframe
                    className={classes.responsiveIframe}
                    src={videoSource}
                    title="Piata drumul taberei"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  />
                </div>
              );
            default:
              break;
          }
          return null;
        })}
      </Carousel>

      <Container maxWidth={false} disableGutters={true} className={classes.lightBackground}>
        <Grid container className={clsx(classes.sectionContainer, classes.sectionPadding)}>
          <Grid item xs={12}>
            <Typography align="center" variant="h1" className={clsx(classes.sectionTitle, classes.textGreen)}>
              Prima piata Verde din România
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={6}>
            <Typography align="center" variant="h3" className={classes.sectionText}>
              Piața Drumul Taberei este într-o continuă dezvoltare și adaptare.
            </Typography>
            <Typography align="center" variant="h3" className={classes.sectionText}>
              În cel mai scurt timp Piața Drumul Taberei va fi cea mai modernă și tehnologizată Piață din România.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={4} className={classes.subsection}>
            <picture>
              <source srcSet={section11.default} type="image/webp" />
              <img src={section11.fallback} className={classes.roundImage} alt="Energie regenerabila" />
            </picture>
            <Typography align="center" variant="h3" className={classes.subsectionTitle}>
              Energie Regenerabilă
            </Typography>
            <Typography align="center" variant="h4" className={classes.sectionText}>
              Pe acoperișul pieței au fost montate panouri solare.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.subsection}>
            <picture>
              <source srcSet={section12.default} type="image/webp" />
              <img src={section12.fallback} className={classes.roundImage} alt="Statii de incarcare" />
            </picture>
            <Typography align="center" variant="h3" className={classes.subsectionTitle}>
              Stații de încărcare
            </Typography>
            <Typography align="center" variant="h4" className={classes.sectionText}>
              În parcare se găsesc stații de încărcare pentru mașinile electrice.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.subsection}>
            <picture>
              <source srcSet={section13.default} type="image/webp" />
              <img src={section13.fallback} className={classes.roundImage} alt="Managementul deseurilor" />
            </picture>
            <Typography align="center" variant="h3" className={classes.subsectionTitle}>
              Managementul Deșeurilor
            </Typography>
            <Typography align="center" variant="h4" className={classes.sectionText}>
              Colectăm deșeurile selectiv și promovăm sistemul zerowaste.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false} disableGutters={true} className={classes.darkBackground}>
        <Grid container className={classes.sectionContainer}>
          <Grid item xs={12} md={6} className={classes.leftImageContainer}>
            <picture>
              <source srcSet={section2.default} type="image/webp" />
              <img src={section2.fallback} className={classes.leftImage} alt="Digitalizare" />
            </picture>
          </Grid>
          <Grid item xs={12} md={6} className={classes.sectionPadding} style={{ alignSelf: 'center' }}>
            <Typography align="center" variant="h1" className={clsx(classes.sectionTitle, classes.textWhite)}>
              Digitalizare
            </Typography>
            <Typography variant="h3" className={classes.section2Text}>
              Piața Drumul Taberei în parteneriat cu Orange lucrează la Proiectul de Digitalizare și vor amplasa
              structura de Wi-Fi în incinta pieței.
            </Typography>
            <Typography variant="h3" className={classes.section2Text}>
              Atât clienții cât și negustorii vor avea accces gratuit la această rețea.
            </Typography>
            <Typography variant="h3" className={classes.section2Text}>
              Am dezvoltat o platforma online, mai exact o aplicație Web care o sa poată fi accesata și prin telefon.
            </Typography>
            <Typography variant="h3" className={classes.section2Text}>
              Această aplicație le dă posibilitatea negustorilor atât să poată comercializa produse online, să le
              prezinte clienților toate detaliile legate de proveniența mărfurilor, cât și să acceseze partea de
              închiriere online.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false} disableGutters={true} className={classes.lightBackground}>
        <Grid container className={classes.sectionPadding}>
          <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
            <picture>
              <source srcSet={section31.default} type="image/webp" />
              <img src={section31.fallback} className={classes.roundImage} alt="Comert modern" />
            </picture>
          </Grid>
          <Grid item xs={12} md={6} style={{ alignSelf: 'center' }} className={classes.sectionPadding}>
            <Typography variant="h1" className={classes.subsectionTitle}>
              Comerț Modern
            </Typography>
            <Typography align="justify" variant="h3" className={classes.sectionText}>
              Condițiile de muncă vor fi aduse la nivelul piețelor din Europa.
            </Typography>
            <Typography align="justify" variant="h3" className={classes.sectionText}>
              Oamenii vor găsi în această piață producători autohtoni care vor comercializa produse organice.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ alignSelf: 'center' }} className={classes.sectionPadding}>
            <Typography variant="h1" className={classes.subsectionTitle}>
              Condiții Moderne
            </Typography>
            <Typography align="justify" variant="h3" className={classes.sectionText}>
              Piața Drumul Taberei va fi dotată cu încălzire și sistem de aer condiționat.
            </Typography>
            <Typography align="justify" variant="h3" className={classes.sectionText}>
              Atât comercianții cât și clienții vor putea vizita piața în orice condiții meteo.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
            <picture>
              <source srcSet={section32.default} type="image/webp" />
              <img src={section32.fallback} className={classes.roundImage} alt="Conditii moderne" />
            </picture>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false} className={classes.galleryBackground}>
        <Grid container className={classes.sectionPadding} spacing={4}>
          <Grid item xs={12}>
            <Typography align="center" variant="h1" className={clsx(classes.sectionTitle, classes.textWhite)}>
              Piata Drumul Taberei
            </Typography>
          </Grid>
          <Gallery options={{ shareEl: false }}>
            {gallery.map((image, index) => (
              <Item key={index} original={image.fallback} width="1300" height="900">
                {({ ref, open }: any) => (
                  <Grid item xs={6} md={3}>
                    <picture>
                      <source srcSet={image.default} type="image/webp" />
                      <img
                        ref={ref}
                        onClick={open}
                        src={image.fallback}
                        className={classes.galleryImage}
                        alt="Piata Drumul Taberei"
                      />
                    </picture>
                  </Grid>
                )}
              </Item>
            ))}
          </Gallery>
        </Grid>
      </Container>
    </Page>
  );
};

export default HomeView;
