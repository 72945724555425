import { default as fallback11 } from './section11.png';
import { default as default11 } from './section11.webp';
import { default as fallback12 } from './section12.png';
import { default as default12 } from './section12.webp';
import { default as fallback13 } from './section13.png';
import { default as default13 } from './section13.webp';
import { default as fallback2 } from './section2.png';
import { default as default2 } from './section2.webp';
import { default as fallback31 } from './section31.png';
import { default as default31 } from './section31.webp';
import { default as fallback32 } from './section32.png';
import { default as default32 } from './section32.webp';

export const section11 = { default: default11, fallback: fallback11 };
export const section12 = { default: default12, fallback: fallback12 };
export const section13 = { default: default13, fallback: fallback13 };
export const section2 = { default: default2, fallback: fallback2 };
export const section31 = { default: default31, fallback: fallback31 };
export const section32 = { default: default32, fallback: fallback32 };
